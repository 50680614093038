html,
body {
  min-height: 100%;
}

body {
  background-color: #ffff;
}

@media (min-width: 674px) {
  body {
    background-color: #f8f9fa;
  }
}
